<app-h1-template [title]="'Trouver une mission'"/>

<div class="missionContainer">

  <div class="missionCard">
    <div class="missionCardBG">
      <div class="missionSectionTitle">
        # Missions IT
      </div>
      <svg class="wave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill-opacity="1"
              d="M0,288L7.1,250.7C14.1,213,28,139,42,128C56.5,117,71,171,85,202.7C98.8,235,113,245,127,229.3C141.2,213,155,171,169,138.7C183.5,107,198,85,212,80C225.9,75,240,85,254,101.3C268.2,117,282,139,296,165.3C310.6,192,325,224,339,213.3C352.9,203,367,149,381,122.7C395.3,96,409,96,424,85.3C437.6,75,452,53,466,64C480,75,494,117,508,154.7C522.4,192,536,224,551,202.7C564.7,181,579,107,593,112C607.1,117,621,203,635,224C649.4,245,664,203,678,176C691.8,149,706,139,720,138.7C734.1,139,748,149,762,170.7C776.5,192,791,224,805,213.3C818.8,203,833,149,847,149.3C861.2,149,875,203,889,186.7C903.5,171,918,85,932,80C945.9,75,960,149,974,186.7C988.2,224,1002,224,1016,192C1030.6,160,1045,96,1059,85.3C1072.9,75,1087,117,1101,138.7C1115.3,160,1129,160,1144,176C1157.6,192,1172,224,1186,213.3C1200,203,1214,149,1228,112C1242.4,75,1256,53,1271,64C1284.7,75,1299,117,1313,144C1327.1,171,1341,181,1355,208C1369.4,235,1384,277,1398,261.3C1411.8,245,1426,171,1433,133.3L1440,96L1440,320L1432.9,320C1425.9,320,1412,320,1398,320C1383.5,320,1369,320,1355,320C1341.2,320,1327,320,1313,320C1298.8,320,1285,320,1271,320C1256.5,320,1242,320,1228,320C1214.1,320,1200,320,1186,320C1171.8,320,1158,320,1144,320C1129.4,320,1115,320,1101,320C1087.1,320,1073,320,1059,320C1044.7,320,1031,320,1016,320C1002.4,320,988,320,974,320C960,320,946,320,932,320C917.6,320,904,320,889,320C875.3,320,861,320,847,320C832.9,320,819,320,805,320C790.6,320,776,320,762,320C748.2,320,734,320,720,320C705.9,320,692,320,678,320C663.5,320,649,320,635,320C621.2,320,607,320,593,320C578.8,320,565,320,551,320C536.5,320,522,320,508,320C494.1,320,480,320,466,320C451.8,320,438,320,424,320C409.4,320,395,320,381,320C367.1,320,353,320,339,320C324.7,320,311,320,296,320C282.4,320,268,320,254,320C240,320,226,320,212,320C197.6,320,184,320,169,320C155.3,320,141,320,127,320C112.9,320,99,320,85,320C70.6,320,56,320,42,320C28.2,320,14,320,7,320L0,320Z"></path>
      </svg>
      <div class="missionContent showMobile">
        <a href="https://e-nov.info/">e-nov.info</a><br>
        <a href="https://www.free-work.com/fr/tech-it">free-work.com</a><br>
        <a href="https://www.malt.fr/">malt.fr</a><br>
        <a href="https://www.freelance-informatique.fr">freelance-informatique.fr</a><br>
        <a href="https://www.lehibou.com/freelances">lehibou.com</a><br>
        <a href="https://www.freelancerepublik.com/freelance">freelancerepublik.com</a><br>
        <a href="https://www.airjob.fr/recherche">airjob.fr/recherche</a><br>
        <a href="https://www.mission-freelance.com/offres-missions-freelance">mission-freelance.com</a><br>
        <a href="https://freelance-day.eu">freelance-day.eu</a><br>
        <a href="https://timfree.fr/consultants/missions">timfree.fr</a><br>
        <a href="https://www.codeur.com">codeur.com</a>
      </div>
      <div class="missionContent showDesktop">
        <a href="https://e-nov.info/">e-nov.info</a><br>
        <a href="https://www.free-work.com/fr/tech-it">free-work.com/fr/tech-it</a><br>
        <a href="https://www.malt.fr/">malt.fr</a><br>
        <a href="https://www.freelance-informatique.fr">freelance-informatique.fr</a><br>
        <a href="https://www.lehibou.com/freelances">lehibou.com/freelances</a><br>
        <a href="https://www.freelancerepublik.com/freelance">freelancerepublik.com/freelance</a><br>
        <a href="https://www.airjob.fr/recherche">airjob.fr/recherche</a><br>
        <a href="https://www.mission-freelance.com/offres-missions-freelance">mission-freelance.com/offres-missions-freelance</a><br>
        <a href="https://freelance-day.eu">freelance-day.eu</a><br>
        <a href="https://timfree.fr/consultants/missions">timfree.fr/consultants/missions</a><br>
        <a href="https://www.codeur.com">codeur.com</a>
      </div>
    </div>
  </div>

  <div class="missionCard">
    <div class="missionCardBG">
      <div class="missionSectionTitle">
        # Rédacteur Web
      </div>
      <svg class="wave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill-opacity="1"
              d="M0,288L7.1,250.7C14.1,213,28,139,42,128C56.5,117,71,171,85,202.7C98.8,235,113,245,127,229.3C141.2,213,155,171,169,138.7C183.5,107,198,85,212,80C225.9,75,240,85,254,101.3C268.2,117,282,139,296,165.3C310.6,192,325,224,339,213.3C352.9,203,367,149,381,122.7C395.3,96,409,96,424,85.3C437.6,75,452,53,466,64C480,75,494,117,508,154.7C522.4,192,536,224,551,202.7C564.7,181,579,107,593,112C607.1,117,621,203,635,224C649.4,245,664,203,678,176C691.8,149,706,139,720,138.7C734.1,139,748,149,762,170.7C776.5,192,791,224,805,213.3C818.8,203,833,149,847,149.3C861.2,149,875,203,889,186.7C903.5,171,918,85,932,80C945.9,75,960,149,974,186.7C988.2,224,1002,224,1016,192C1030.6,160,1045,96,1059,85.3C1072.9,75,1087,117,1101,138.7C1115.3,160,1129,160,1144,176C1157.6,192,1172,224,1186,213.3C1200,203,1214,149,1228,112C1242.4,75,1256,53,1271,64C1284.7,75,1299,117,1313,144C1327.1,171,1341,181,1355,208C1369.4,235,1384,277,1398,261.3C1411.8,245,1426,171,1433,133.3L1440,96L1440,320L1432.9,320C1425.9,320,1412,320,1398,320C1383.5,320,1369,320,1355,320C1341.2,320,1327,320,1313,320C1298.8,320,1285,320,1271,320C1256.5,320,1242,320,1228,320C1214.1,320,1200,320,1186,320C1171.8,320,1158,320,1144,320C1129.4,320,1115,320,1101,320C1087.1,320,1073,320,1059,320C1044.7,320,1031,320,1016,320C1002.4,320,988,320,974,320C960,320,946,320,932,320C917.6,320,904,320,889,320C875.3,320,861,320,847,320C832.9,320,819,320,805,320C790.6,320,776,320,762,320C748.2,320,734,320,720,320C705.9,320,692,320,678,320C663.5,320,649,320,635,320C621.2,320,607,320,593,320C578.8,320,565,320,551,320C536.5,320,522,320,508,320C494.1,320,480,320,466,320C451.8,320,438,320,424,320C409.4,320,395,320,381,320C367.1,320,353,320,339,320C324.7,320,311,320,296,320C282.4,320,268,320,254,320C240,320,226,320,212,320C197.6,320,184,320,169,320C155.3,320,141,320,127,320C112.9,320,99,320,85,320C70.6,320,56,320,42,320C28.2,320,14,320,7,320L0,320Z"></path>
      </svg>
      <div class="missionContent showMobile">
        <a href="https://apply.workable.com/textmaster">apply.workable.com</a><br>
        <a href="https://www.textbroker.fr/je-suis-auteur">textbroker.fr</a><br>
        <a href="https://www.redacteur.com">redacteur.com</a><br>
        <a href="https://www.redactiweb.com">redactiweb.com</a><br>
        <a href="https://www.scribbr.fr/jobs/redacteur-scribbr">scribbr.fr</a>
      </div>
      <div class="missionContent showDesktop">
        <a href="https://apply.workable.com/textmaster">apply.workable.com/textmaster</a><br>
        <a href="https://www.textbroker.fr/je-suis-auteur">textbroker.fr/je-suis-auteur</a><br>
        <a href="https://www.redacteur.com">redacteur.com</a><br>
        <a href="https://www.redactiweb.com">redactiweb.com</a><br>
        <a href="https://www.scribbr.fr/jobs/redacteur-scribbr">scribbr.fr/jobs/redacteur-scribbr</a>
      </div>
    </div>
  </div>

  <div class="missionCard">
    <div class="missionCardBG">
      <div class="missionSectionTitle">
        # Missions Multiples
      </div>
      <svg class="wave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill-opacity="1"
              d="M0,288L7.1,250.7C14.1,213,28,139,42,128C56.5,117,71,171,85,202.7C98.8,235,113,245,127,229.3C141.2,213,155,171,169,138.7C183.5,107,198,85,212,80C225.9,75,240,85,254,101.3C268.2,117,282,139,296,165.3C310.6,192,325,224,339,213.3C352.9,203,367,149,381,122.7C395.3,96,409,96,424,85.3C437.6,75,452,53,466,64C480,75,494,117,508,154.7C522.4,192,536,224,551,202.7C564.7,181,579,107,593,112C607.1,117,621,203,635,224C649.4,245,664,203,678,176C691.8,149,706,139,720,138.7C734.1,139,748,149,762,170.7C776.5,192,791,224,805,213.3C818.8,203,833,149,847,149.3C861.2,149,875,203,889,186.7C903.5,171,918,85,932,80C945.9,75,960,149,974,186.7C988.2,224,1002,224,1016,192C1030.6,160,1045,96,1059,85.3C1072.9,75,1087,117,1101,138.7C1115.3,160,1129,160,1144,176C1157.6,192,1172,224,1186,213.3C1200,203,1214,149,1228,112C1242.4,75,1256,53,1271,64C1284.7,75,1299,117,1313,144C1327.1,171,1341,181,1355,208C1369.4,235,1384,277,1398,261.3C1411.8,245,1426,171,1433,133.3L1440,96L1440,320L1432.9,320C1425.9,320,1412,320,1398,320C1383.5,320,1369,320,1355,320C1341.2,320,1327,320,1313,320C1298.8,320,1285,320,1271,320C1256.5,320,1242,320,1228,320C1214.1,320,1200,320,1186,320C1171.8,320,1158,320,1144,320C1129.4,320,1115,320,1101,320C1087.1,320,1073,320,1059,320C1044.7,320,1031,320,1016,320C1002.4,320,988,320,974,320C960,320,946,320,932,320C917.6,320,904,320,889,320C875.3,320,861,320,847,320C832.9,320,819,320,805,320C790.6,320,776,320,762,320C748.2,320,734,320,720,320C705.9,320,692,320,678,320C663.5,320,649,320,635,320C621.2,320,607,320,593,320C578.8,320,565,320,551,320C536.5,320,522,320,508,320C494.1,320,480,320,466,320C451.8,320,438,320,424,320C409.4,320,395,320,381,320C367.1,320,353,320,339,320C324.7,320,311,320,296,320C282.4,320,268,320,254,320C240,320,226,320,212,320C197.6,320,184,320,169,320C155.3,320,141,320,127,320C112.9,320,99,320,85,320C70.6,320,56,320,42,320C28.2,320,14,320,7,320L0,320Z"></path>
      </svg>
      <div class="missionContent showMobile">
        <a href="https://www.missions-freelance.com">missions-freelance.com</a><br>
        <a href="https://www.lesbonsfreelances.com/missions">lesbonsfreelances.com</a><br>
        <a href="https://www.404works.com/fr/projects">404works.com</a><br>
        <a href="https://www.freejob.fr/recherche-mission-ou-projet">freejob.fr</a><br>
        <a href="https://www.myindep.fr/offres-missions-consultant-freelance">myindep.fr</a>
      </div>
      <div class="missionContent showDesktop">
        <a href="https://www.missions-freelance.com">missions-freelance.com</a><br>
        <a href="https://www.lesbonsfreelances.com/missions">lesbonsfreelances.com/missions</a><br>
        <a href="https://www.404works.com/fr/projects">404works.com/fr/projects</a><br>
        <a href="https://www.freejob.fr/recherche-mission-ou-projet">freejob.fr/recherche-mission-ou-projet</a><br>
        <a href="https://www.myindep.fr/offres-missions-consultant-freelance">myindep.fr/offres-missions-consultant-freelance</a>
      </div>
    </div>
  </div>

  <div class="missionCard">
    <div class="missionCardBG">
      <div class="missionSectionTitle">
        # Missions Finance & Comptabilité
      </div>
      <svg class="wave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill-opacity="1"
              d="M0,288L7.1,250.7C14.1,213,28,139,42,128C56.5,117,71,171,85,202.7C98.8,235,113,245,127,229.3C141.2,213,155,171,169,138.7C183.5,107,198,85,212,80C225.9,75,240,85,254,101.3C268.2,117,282,139,296,165.3C310.6,192,325,224,339,213.3C352.9,203,367,149,381,122.7C395.3,96,409,96,424,85.3C437.6,75,452,53,466,64C480,75,494,117,508,154.7C522.4,192,536,224,551,202.7C564.7,181,579,107,593,112C607.1,117,621,203,635,224C649.4,245,664,203,678,176C691.8,149,706,139,720,138.7C734.1,139,748,149,762,170.7C776.5,192,791,224,805,213.3C818.8,203,833,149,847,149.3C861.2,149,875,203,889,186.7C903.5,171,918,85,932,80C945.9,75,960,149,974,186.7C988.2,224,1002,224,1016,192C1030.6,160,1045,96,1059,85.3C1072.9,75,1087,117,1101,138.7C1115.3,160,1129,160,1144,176C1157.6,192,1172,224,1186,213.3C1200,203,1214,149,1228,112C1242.4,75,1256,53,1271,64C1284.7,75,1299,117,1313,144C1327.1,171,1341,181,1355,208C1369.4,235,1384,277,1398,261.3C1411.8,245,1426,171,1433,133.3L1440,96L1440,320L1432.9,320C1425.9,320,1412,320,1398,320C1383.5,320,1369,320,1355,320C1341.2,320,1327,320,1313,320C1298.8,320,1285,320,1271,320C1256.5,320,1242,320,1228,320C1214.1,320,1200,320,1186,320C1171.8,320,1158,320,1144,320C1129.4,320,1115,320,1101,320C1087.1,320,1073,320,1059,320C1044.7,320,1031,320,1016,320C1002.4,320,988,320,974,320C960,320,946,320,932,320C917.6,320,904,320,889,320C875.3,320,861,320,847,320C832.9,320,819,320,805,320C790.6,320,776,320,762,320C748.2,320,734,320,720,320C705.9,320,692,320,678,320C663.5,320,649,320,635,320C621.2,320,607,320,593,320C578.8,320,565,320,551,320C536.5,320,522,320,508,320C494.1,320,480,320,466,320C451.8,320,438,320,424,320C409.4,320,395,320,381,320C367.1,320,353,320,339,320C324.7,320,311,320,296,320C282.4,320,268,320,254,320C240,320,226,320,212,320C197.6,320,184,320,169,320C155.3,320,141,320,127,320C112.9,320,99,320,85,320C70.6,320,56,320,42,320C28.2,320,14,320,7,320L0,320Z"></path>
      </svg>
      <div class="missionContent showMobile">
        <a href="https://www.finstart.io">finstart.io</a><br>
        <a href="https://app.beager.com/public/onboarding/freelance/prestep">app.beager.com</a>
      </div>
      <div class="missionContent showDesktop">
        <a href="https://www.finstart.io">finstart.io</a><br>
        <a href="https://app.beager.com/public/onboarding/freelance/prestep">app.beager.com/public/onboarding/freelance/prestep</a>
      </div>
    </div>
  </div>

  <div class="missionCard">
    <div class="missionCardBG">
      <div class="missionSectionTitle">
        # Missions de Téléprospection
      </div>
      <svg class="wave" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 1440 320">
        <path fill-opacity="1"
              d="M0,288L7.1,250.7C14.1,213,28,139,42,128C56.5,117,71,171,85,202.7C98.8,235,113,245,127,229.3C141.2,213,155,171,169,138.7C183.5,107,198,85,212,80C225.9,75,240,85,254,101.3C268.2,117,282,139,296,165.3C310.6,192,325,224,339,213.3C352.9,203,367,149,381,122.7C395.3,96,409,96,424,85.3C437.6,75,452,53,466,64C480,75,494,117,508,154.7C522.4,192,536,224,551,202.7C564.7,181,579,107,593,112C607.1,117,621,203,635,224C649.4,245,664,203,678,176C691.8,149,706,139,720,138.7C734.1,139,748,149,762,170.7C776.5,192,791,224,805,213.3C818.8,203,833,149,847,149.3C861.2,149,875,203,889,186.7C903.5,171,918,85,932,80C945.9,75,960,149,974,186.7C988.2,224,1002,224,1016,192C1030.6,160,1045,96,1059,85.3C1072.9,75,1087,117,1101,138.7C1115.3,160,1129,160,1144,176C1157.6,192,1172,224,1186,213.3C1200,203,1214,149,1228,112C1242.4,75,1256,53,1271,64C1284.7,75,1299,117,1313,144C1327.1,171,1341,181,1355,208C1369.4,235,1384,277,1398,261.3C1411.8,245,1426,171,1433,133.3L1440,96L1440,320L1432.9,320C1425.9,320,1412,320,1398,320C1383.5,320,1369,320,1355,320C1341.2,320,1327,320,1313,320C1298.8,320,1285,320,1271,320C1256.5,320,1242,320,1228,320C1214.1,320,1200,320,1186,320C1171.8,320,1158,320,1144,320C1129.4,320,1115,320,1101,320C1087.1,320,1073,320,1059,320C1044.7,320,1031,320,1016,320C1002.4,320,988,320,974,320C960,320,946,320,932,320C917.6,320,904,320,889,320C875.3,320,861,320,847,320C832.9,320,819,320,805,320C790.6,320,776,320,762,320C748.2,320,734,320,720,320C705.9,320,692,320,678,320C663.5,320,649,320,635,320C621.2,320,607,320,593,320C578.8,320,565,320,551,320C536.5,320,522,320,508,320C494.1,320,480,320,466,320C451.8,320,438,320,424,320C409.4,320,395,320,381,320C367.1,320,353,320,339,320C324.7,320,311,320,296,320C282.4,320,268,320,254,320C240,320,226,320,212,320C197.6,320,184,320,169,320C155.3,320,141,320,127,320C112.9,320,99,320,85,320C70.6,320,56,320,42,320C28.2,320,14,320,7,320L0,320Z"></path>
      </svg>
      <div class="missionContent showMobile">
        <a href="https://qwincy.fr/freelance">qwincy.fr</a><br>
        <a href="https://jobphoning.com/liste-missions">jobphoning.com</a>
      </div>
      <div class="missionContent showDesktop">
        <a href="https://qwincy.fr/freelance">qwincy.fr/freelance</a><br>
        <a href="https://jobphoning.com/liste-missions">jobphoning.com/liste-missions</a>
      </div>
    </div>
  </div>
</div>
