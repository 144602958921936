import {Component, OnInit} from '@angular/core';
import {FormBuilder, FormGroup, Validators, ReactiveFormsModule} from '@angular/forms';
import {NgClass, NgIf} from "@angular/common";
import {ContactService} from "../../../services/contact.service";
import {RouterLink} from "@angular/router";
import {AppRoutes} from "../../../routing/app-routes.enum";
import {RecaptchaService} from "../../../services/recaptcha.service";
import {ReturnPopinComponent} from "../return-popin/return-popin.component";
import {ReturnPopinTypesEnum} from "../../../models/return-popin-types.enum";

@Component({
  selector: 'app-contact-form',
  standalone: true,
  imports: [
    ReactiveFormsModule,
    NgIf,
    NgClass,
    RouterLink,
    ReturnPopinComponent,
  ],
  templateUrl: './contact-form.component.html',
  styleUrl: './contact-form.component.css'
})
export class ContactFormComponent {
  contactForm: FormGroup;
  public readonly PORTAGILE_ROUTES_CGU: string = "/" + AppRoutes.CGU
  public readonly PORTAGILE_ROUTES_COOKIES: string = "/" + AppRoutes.COOKIES

  returnPopinMessage: string = "";
  returnType: ReturnPopinTypesEnum = ReturnPopinTypesEnum.NULL;

  constructor(private readonly recaptchaService: RecaptchaService, private contactService: ContactService, private fb: FormBuilder) {
    this.contactForm = this.fb.group({
      mail: ['', [Validators.required, Validators.email]],
      telephone: ['', [Validators.required, Validators.pattern(/^[0-9]{10}$/)]],
      nom: ['', Validators.required],
      prenom: ['', Validators.required],
      sujet: ['', Validators.required],
      message: ['', Validators.required],
      consent: [false, Validators.requiredTrue],
      cookiesConsent: [false, Validators.requiredTrue],
    });
  }

  onSubmit() {
    if (this.contactForm.valid) {
      this.recaptchaService.load("6Le4SnAqAAAAAEXpkk0d3_P6N91Dq6akwO2_3LyN")
        .then(() => {
          this.recaptchaService.execute('contactAction', recaptchaToken => {
            const model = {
              ...this.contactForm.value,
              recaptchaToken
            };

            this.contactService.sendContact(model).subscribe({
              next: response => {
                console.log("Réponse du serveur :", response);
                this.returnPopinMessage=response.message
                this.returnType = ReturnPopinTypesEnum.SUCCESS;
                this.contactForm.reset();
              },
              error: error => {
                if (error.status === 429) {
                  console.error("Trop de requêtes : ", error);
                  this.returnPopinMessage="Vous avez fait trop de demandes. Veuillez patienter un moment avant de réessayer."
                  this.returnType = ReturnPopinTypesEnum.WARNING;
                } else {
                  console.error("Erreur lors de l'envoi du message :", error);
                  this.returnPopinMessage="Une erreur est survenue lors de l'envoi, veuillez réessayer plus tard."
                  this.returnType = ReturnPopinTypesEnum.ERROR;
                }
              }
            });
          });
        })
        .catch(error => {
          console.error("Erreur lors du chargement de reCAPTCHA :", error);
          this.returnPopinMessage="Impossible de charger reCAPTCHA. Veuillez réessayer plus tard."
          this.returnType = ReturnPopinTypesEnum.ERROR;
        });
    } else {
      console.log('Formulaire non valide');
    }
  }

  closeReturnPopin() {
    this.returnType = ReturnPopinTypesEnum.NULL;
    this.returnPopinMessage = "";
  }

}
