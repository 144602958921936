import {Component, Input} from '@angular/core';
import {NgClass, NgIf} from "@angular/common";
import {ReturnPopinTypesEnum} from "../../../models/return-popin-types.enum";

@Component({
  selector: 'app-return-popin',
  standalone: true,
  imports: [
    NgClass,
    NgIf
  ],
  templateUrl: './return-popin.component.html',
  styleUrl: './return-popin.component.css'
})
export class ReturnPopinComponent {
  @Input() message: string = "";
  @Input() returnType: ReturnPopinTypesEnum = ReturnPopinTypesEnum.NULL;
  protected readonly ReturnPopinTypesEnum = ReturnPopinTypesEnum;
}
