<div class="form-container">
  <form [formGroup]="contactForm" (ngSubmit)="onSubmit()" class="form">
    <app-return-popin [returnType]="returnType" [message]="returnPopinMessage" (click)="closeReturnPopin()"></app-return-popin>
    <p class="title">Contactez-nous</p>
    <p class="message">Envoyez-nous un message et nous reviendrons vers vous rapidement.</p>

    <label>
      <input id="nom" type="text" formControlName="nom" placeholder="" class="input" required>
      <span>Nom</span>
      <div *ngIf="contactForm.get('nom')?.invalid && contactForm.get('nom')?.touched" class="error">
        Veuillez entrer votre nom.
      </div>
    </label>

    <label>
      <input id="prenom" type="text" formControlName="prenom" placeholder="" class="input" required>
      <span>Prénom</span>
      <div *ngIf="contactForm.get('prenom')?.invalid && contactForm.get('prenom')?.touched" class="error">
        Veuillez entrer votre prénom.
      </div>
    </label>

    <label>
      <input id="mail" type="email" formControlName="mail" placeholder="" class="input" required>
      <span>Email</span>
      <div *ngIf="contactForm.get('mail')?.invalid && contactForm.get('mail')?.touched" class="error">
        Veuillez entrer une adresse email valide.
      </div>
    </label>

    <label>
      <input id="telephone" type="text" formControlName="telephone" placeholder="" class="input" required>
      <span>Téléphone</span>
      <div *ngIf="contactForm.get('telephone')?.invalid && contactForm.get('telephone')?.touched" class="error">
        Veuillez entrer un numéro de téléphone valide (10 chiffres).
      </div>
    </label>

    <label>
      <input id="sujet" type="text" formControlName="sujet" placeholder="" class="input" required>
      <span>Sujet</span>
      <div *ngIf="contactForm.get('sujet')?.invalid && contactForm.get('sujet')?.touched" class="error">
        Veuillez entrer un sujet.
      </div>
    </label>

    <label>
      <textarea id="message" formControlName="message" placeholder="" class="input" required></textarea>
      <span>Message</span>
      <div *ngIf="contactForm.get('message')?.invalid && contactForm.get('message')?.touched" class="error">
        Veuillez entrer un message.
      </div>
    </label>

    <label id="consentLabel">
      <input id="consent" type="checkbox" formControlName="consent" class="input" required>
      <span>J'accepte que mes informations soient utilisées conformément à la <a [routerLink]="[PORTAGILE_ROUTES_CGU]">politique de confidentialité</a>.</span>
    </label>

    <label id="cookiesConsentLabel">
      <input id="cookiesConsent" type="checkbox" formControlName="cookiesConsent" class="input" required>
      <span>J'accepte l'utilisation de cookies selon la <a [routerLink]="[PORTAGILE_ROUTES_COOKIES]">politique de gestion des cookies</a>.</span>
    </label>

    <button id="submitForm" type="submit" [disabled]="contactForm.invalid"
            [ngClass]="contactForm.valid ? 'validButton' : 'invalidButton'" class="submit">
      Envoyer

      <div *ngIf="contactForm.invalid" class="tooltip-text">
        <div *ngIf="contactForm.get('nom')?.invalid ||
                 contactForm.get('prenom')?.invalid ||
                 contactForm.get('mail')?.invalid ||
                 contactForm.get('telephone')?.invalid ||
                 contactForm.get('sujet')?.invalid ||
                 contactForm.get('message')?.invalid">
          Merci de remplir les champs suivants :
        </div>

        <div *ngIf="contactForm.get('nom')?.invalid" class="error">
          - Nom
        </div>

        <div *ngIf="contactForm.get('prenom')?.invalid" class="error">
          - Prénom
        </div>

        <div *ngIf="contactForm.get('mail')?.invalid" class="error">
          - Email
        </div>

        <div *ngIf="contactForm.get('telephone')?.invalid" class="error">
          - Téléphone
        </div>

        <div *ngIf="contactForm.get('sujet')?.invalid" class="error">
          - Sujet
        </div>

        <div *ngIf="contactForm.get('message')?.invalid" class="error">
          - Message
        </div>

        <div *ngIf="(contactForm.get('consent')?.invalid || contactForm.get('cookiesConsent')?.invalid)"
             class="error consent-error">
          Veuillez accepter la politique de confidentialité et la gestion des cookies pour continuer.
        </div>
      </div>
    </button>
  </form>
</div>
